

import React from "react";
import { GameDevelopmentIcon } from "../../components/layout/icons/GameDevelopmentIcon";
import { SoftwareDevelopmentIcon } from "../../components/layout/icons/SoftwareDevelopmentIcon";
import { ServiceData } from "../../components/sections/index/OurServices";
import { WorkData } from "../../components/sections/index/OurWork";

export const indexPageData: IndexPageData = {
    pageTitle: "iDream Interactive - We Make Video Games & Software",
    keywords: "idream interactive, game development outsourcing, outsource mobile app development, outsource software development, game development jobs",
    title: "We create digital experiences",
    serviceData: {
        title: "Our Services",
        services: [
            {
                title: "Game Development",
                copy: "iDream Interactive has been developing games for 15 years.  Let us use our accumulated knowledge and expertise to help you build your dream game.  Dive in to learn more about the individual game development services we can provide.",
                icon: <GameDevelopmentIcon fill={"#03041d"} />,
                buttonLabel: "Learn More",
                link: "/game-development",
                featuredImage: "",
            },
            {
                title: "Software Development",
                copy: "When it comes to software development, the iDream team is prepared to build the custom software you require to solve your individualized problem. We invite you to learn more about the different software development services we offer.",
                icon: <SoftwareDevelopmentIcon fill={"#03041d"} />,
                buttonLabel: "Learn More",
                link: "/software-development",
                featuredImage: "",
            }
        ]
    },
    workData: {
        title: "Our Work",
        buttonLabel: "View Work",
        work: [
            {
                image: {
                    name: "runebornCard",
                    src: "",
                    alt: "Runeborn",
                },
                url: "https://runeborn.com/",
                external: true
            },
            {
                image: {
                    name: "rrCard",
                    src: "",
                    alt: "Recipe Rescue",
                },
                url: "recipe-rescue"
            },

        ]
    }
}

export type IndexPageData = {
    pageTitle: string,
    keywords: string,
    title: string,
    serviceData: ServiceData,
    workData: WorkData,
}