import React from "react";
import { SeparatorSquareIcon } from "../layout/icons/SeparatorSquareIcon";

interface Props {
    opacity: number;
}

export const Separator: React.FC<Props> = ({ opacity }) => {
    return (
        <div className="separator" style={{ opacity }}>
            <SeparatorSquareIcon fill="" className="icon left-icon" />
            <SeparatorSquareIcon fill="" className="icon right-icon" />
        </div>
    )
}