import React from "react";

interface Props {
    fill: string;
}

export const GameDevelopmentIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} className="icon">
            <path d="M0,11V7c0-0.6,0.4-1,1-1h2.6c0.3,0,0.5,0.1,0.7,0.3L7,9l-2.7,2.7C4.1,11.9,3.9,12,3.6,12H1C0.4,12,0,11.6,0,11
            z"/>
            <path d="M7,0h4c0.6,0,1,0.4,1,1v2.6c0,0.3-0.1,0.5-0.3,0.7L9,7L6.3,4.3C6.1,4.1,6,3.9,6,3.6V1C6,0.4,6.4,0,7,0z" />
            <path d="M18,7v4c0,0.6-0.4,1-1,1h-2.6c-0.3,0-0.5-0.1-0.7-0.3L11,9l2.7-2.7C13.9,6.1,14.1,6,14.4,6H17
            C17.6,6,18,6.4,18,7z"/>
            <path d="M11,18H7c-0.6,0-1-0.4-1-1v-2.6c0-0.3,0.1-0.5,0.3-0.7L9,11l2.7,2.7c0.2,0.2,0.3,0.4,0.3,0.7V17
            C12,17.6,11.6,18,11,18z"/>
        </svg>
    )
}