import React from "react"
import { Link } from "gatsby"
import Img from 'gatsby-image'
import { Button, ButtonColour, ButtonSize } from "../../layout/Button"
import { BlobOne } from "../../layout/blobs/BlobOne";
import { BlobThree } from "../../layout/blobs/BlobThree";

interface Props {
    workData: WorkData;
    isFront?: boolean;
}

export type WorkData = {
    title?: string,
    buttonLabel?: string,
    work: WorkItem[],
}

export type WorkItem = {
    image: ItemImage,
    url: string,
    external?: boolean;
}

export type ItemImage = {
    name: string,
    src: any,
    alt: string,
}

export const OurWork: React.FC<Props> = ({ workData, isFront }) => {
    let delay = 0;
    return (
        <div className="work-container">
            <div className="blob-container">
                <BlobOne fill={"#080a2e08"} />
                <BlobThree fill={"#080a2e08"} />
            </div>
            {workData.title &&
                <h2 className="title" data-sal="slide-up">{workData.title}</h2>
            }

            <div className={`products${isFront ? " is-front" : ""}`}>
                {
                    workData.work.map((product, index) => {
                        delay += 50;
                        if (index % 4 === 0) {
                            delay = 0;
                        }

                        if (product.external) {
                            return (
                                <a href={product.url} key={product.image.name} target="_blank" className="work-card" data-sal="slide-up" data-sal-delay={delay}>
                                    <Img fluid={product.image.src} alt={product.image.alt} />
                                </a>
                            )
                        }

                        return (
                            <Link to={`/work/${product.url}`} key={product.image.name} className="work-card" data-sal="slide-up" data-sal-delay={delay}>
                                <Img fluid={product.image.src} alt={product.image.alt} />
                            </Link>
                        );
                    })
                }
            </div>

            {workData.buttonLabel &&
                <Button
                    data-sal="slide-up"
                    buttonConfig={{
                        id: "workGradient",
                        ctaLink: "/work",
                        ctaLabel: workData.buttonLabel,
                        colour: ButtonColour.BLUE,
                        size: ButtonSize.LARGE,
                        ariaLabel: "View Our Work"
                    }}
                />
            }
        </div>
    )
}