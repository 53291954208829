import React from "react";

interface Props {
    fill: string;
}

export const MinusIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill={fill}>
            <path d="M3,8h12v2H3V8z" />
        </svg>
    )
}