import React from "react"
import { Button, ButtonColour, ButtonSize } from "../../layout/Button"
import { MinusIcon } from "../../layout/icons/MinusIcon"
import { PlusIcon } from "../../layout/icons/PlusIcon"

interface Props {
    service: ServiceInfo;
    onClick: () => void;
    isOpen?: boolean;
}

export type ServiceInfo = {
    title: string,
    icon: JSX.Element,
    buttonLabel: string,
    copy: string,
    link: string,
    featuredImage: any;
}

export const Service: React.FC<Props> = ({ service, isOpen, onClick }) => {

    return (
        <div className="service">
            <div className="service-title" onClick={onClick}>
                {service.icon}
                <h2 data-sal="slide-up">{service.title}</h2>
                {isOpen ?
                    <MinusIcon fill={"#03041d"} /> :
                    <PlusIcon fill={"#03041d"} />}
            </div>
            {
                isOpen &&
                <div className="service-content">
                    <p className="body">{service.copy}</p>
                    <Button
                        buttonConfig={{
                            id: "serviceGradient",
                            colour: ButtonColour.BLACK,
                            size: ButtonSize.LARGE,
                            ctaLabel: service.buttonLabel,
                            ctaLink: service.link,
                            ariaLabel: `Learn more about ${service.buttonLabel}`
                        }}
                    />
                </div>
            }
        </div >
    )
}