import React from "react"

interface Props { }

export const RunebornLogo: React.FC<Props> = ({ }) => {

    return (
        <svg className="runeborn-logo" viewBox="0 0 2851 1004.8">
            <g>
                <g>
                    <path d="M1922.2,332.7c1.9-0.3,3.5-0.4,5-0.5h0.3c2-0.1,3.7-0.2,5.3-0.2c7.4,0,14.6,1,21.5,2.9l12.9,3.6l-0.7-42.9l-0.4-56.8
			L1941.6,0l-23.9,233.7c-44.4,2.6-70.2,10.8-106,33.2c-36.1,22.5-64.5,54.6-84.7,95.2c-20,40.4-30.1,88.3-30.1,142.3
			c0,9.6,0.3,19,1,28.2c-3.2-3.6-6.6-7-10.2-10.1c-10.4-9.1-22.8-16.4-37-21.8c6.2-3.4,11.9-7.2,16.9-11.3
			c25.5-21.2,38.5-53,38.5-94.6c0-39.9-13.4-70.2-39.9-90.3c-25.6-19.4-64.4-29.2-115.4-29.2h-18.5l-2.9,3.1c-4.9,5-7.4,6.9-8.6,7.5
			c-2.1-0.6-7.6-3-17.5-9l-2.4-1.5h-127.5v28.5l38.8,45.2V377l24.2,21.9l-0.7,0.8l-23.5,4.6V709l-38.8,45.2v28.5h170.1
			c19.2,0,37.2-1.5,53.5-4.5l6.1-1.1l1.7-6c1.6-5.6,3.8-13,5.7-19l31.4,12.6l4-1.9c26.2-12.5,46.5-30.8,60.3-54.2
			c11.1-18.8,17.9-41,20.3-66.1c19.5,39.3,47.1,70.3,82,92c19.4,12.1,41.1,20.9,64.3,26.3l12.8,3l-1.4-35.3l12.9,3L1894,656l-3.3-3
			c-15.2-14.3-27.2-34.9-35.7-61.4c-8.7-27.3-13.1-60.2-13.1-97.8c0-34.8,3.9-64.8,11.5-89.3c7.4-23.7,18.2-42,32-54.4
			c1.5-1.4,3.1-2.7,4.9-4.1c0.3-0.2,0.6-0.4,0.9-0.6s0.6-0.4,1-0.7l0.2-0.1c1.1-0.7,2.1-1.5,3-2c0.6-0.4,1.2-0.7,1.8-1.1l1.2-0.7
			c0.7-0.4,1.4-0.8,2.2-1.2c0.9-0.5,2-0.9,3.1-1.4l0.2-0.1c0.6-0.3,1.3-0.6,2-0.9c1-0.4,2.2-0.9,3.5-1.3c0.6-0.2,1.2-0.4,1.9-0.6
			c1.4-0.4,2.7-0.8,4-1.1c0.4-0.1,0.9-0.2,1.3-0.3h0.2c1.7-0.4,3.2-0.6,4.6-0.9C1921.3,332.8,1922.2,332.7,1922.2,332.7z
			 M1590.4,463.3c-7.9,11.6-18.6,17.8-33,19h-0.1L1514,487V334.7h27.8c26.3,0,61.3,7.9,61.3,76.2
			C1603,433.3,1598.8,450.9,1590.4,463.3z M1514,723.1V543.9h38.9c20.7,0,36.2,7,47.5,21.3c11.6,14.8,17.6,37.4,17.6,66.9
			c0,29.9-6.6,52.9-19.7,68.4c-12.8,15.2-31.3,22.6-56.5,22.6L1514,723.1L1514,723.1z"/>
                    <path d="M2524,651.4l-0.4-0.4l-0.4-0.4c-0.4-0.4-36.7-36.3-72.6-75.9c-48.3-53.2-63.5-77.2-68.3-87.6
			c13.6-4.7,25.6-10.7,35.9-18.2c28-20.2,42.2-48.5,42.2-84.1c0-35.3-13.6-62.8-40.4-81.8c-14.9-10.6-33.8-18.2-56.1-22.6l-4.8-0.9
			l-3.7,3.2c-10,8.6-15.5,10.9-16.7,11c-0.2,0-0.4,0-0.6,0c-0.4,0-0.7,0-0.9,0c-0.3-0.5-0.6-1.1-1-1.7c-0.8-1.1-1.5-2.2-13.9-13.7
			l-2.8-2.6l-3.8-0.1c-2,0-4.1-0.1-6.2-0.1h-185.6v25.7l38.8,34.1v46.3c-3.4-9.6-7.3-18.9-11.6-27.8c-19.6-39.9-47.5-71.3-83.1-93.4
			c-15.5-9.7-32.5-17.3-50.5-22.8l-13.5-4.1l1.9,47.1c-0.4-0.1-0.8-0.2-1.2-0.4l-13.5-4.1l3.5,86.4l1.9,2.5
			c9.8,12.9,18,29.2,24.2,48.6c1.6,5.1,3.1,10.5,4.5,16.1l2.3,9.5l10.4-2.2c2.7,1.3,5.4,2.6,7.2,3.5c-0.6,1-1.4,2.3-2.5,4l-12.7,8.8
			l0.8,6.1c2.1,16.1,3.2,33.7,3.2,52.4c0,34.8-3.9,64.8-11.5,89.3c-7.4,23.6-18,41.8-31.6,54.3l-0.1,0.1l-0.2,0.2
			c-1.3,1.1-2.7,2.3-4.3,3.6c-0.5,0.4-1,0.8-1.6,1.2c-1,0.7-2,1.4-3.1,2.1l-0.7,0.5c-0.4,0.3-0.8,0.5-1.1,0.7
			c-1.3,0.8-2.7,1.6-4.1,2.3c-0.9,0.5-2,0.9-3.1,1.4c-0.5,0.2-0.9,0.4-1.4,0.6c-8.6,3.6-18.1,5.4-28.2,5.4c-3.4,0-6.9-0.2-10.3-0.7
			l-11.5-1.5l0.6,38.8l0.5,62.5l23.8,232.1l24.3-237.4c35-4.2,66.7-15.1,94.2-32.3c36.6-22.9,65.4-55.5,85.6-97.1
			c4.7-9.8,8.9-19.9,12.6-30.5V670l-38.8,34.1v25.7h179.6v-25.7l-38.8-34.1V325.6h18.7c21.3,0,37.6,5.6,49.8,17.2
			c12.1,11.5,17.9,26.8,17.9,46.9c0,20.5-6,36.5-18.4,48.9c-13.2,13.2-25.9,18.4-45.3,18.4h-10l-0.1,41.7l184.2,231.1h193.6
			l-53-19.4C2573.1,699.9,2547.6,680.6,2524,651.4z"/>
                    <path d="M2700.6,275.7v25.7L2740,336l20.6,176.8l-138.5-176l-7.4,3.8c-4.2,2.2-6.4,2.9-7.2,3.1c-7.5-1.1-11.8-3.5-13.2-7.3
			c-0.7-1.9,0.2-8.5,5.2-19l2.7-5.6l-28.4-36.1h-106.9v24.9l40.3,46.8l-7.5,249.2l2,2.7c1.4,1.9,2.8,3.9,4.2,5.8l3.1,4.2l0.5,0.5
			c9.2,9.5,18.2,18.6,26.9,27.2l1.1,1.1l1,1.2c3.8,4.7,8,9.4,12.7,14.3l18.9,19.7l-16-253.8l90.3,138.5l8.4-5.7
			c8.9-6,15.3-9.7,17.7-10.8c1.1,0.2,2.6,0.9,3.6,1.6c-0.7,7-5.9,27.5-9.8,38.1l-1.7,4.7l93.7,143.7h55.3v-394l39.4-34.1v-25.8
			H2700.6z"/>
                    <path d="M1386,677l-170.9,6.5V522.2l61.9,3.4l40.7,38.5h25.7V432h-25.7l-40.8,38.6l-61.7,3.6V321.6l169.5,7.1l-23.9-21v-32.5
			l-286.3,0.6V301l38.8,34.1v29.7l2.6,2.9c7.1,7.8,17.8,19.1,24,25.7l-26.6,11V670l-38.8,34.1v25.7h280.7l31-33V677H1386z"/>
                    <path d="M916.8,301.4l39.3,34.6l20.6,176.8L790.1,275.7H683.2v24.9l40.3,46.8l-9.3,322.5l-39,34.2v25.7H825v-25.7l-39.1-34.4
			l-15.6-250l54.5,83.7l8.7-9.6c5.1-5.6,8.3-7,9-7.1c1.1,0.1,1.8,0.2,2.1,0.3c0.1,0.3,0.2,0.8,0.3,1.2c0.2,0.6,0.3,1.2,0.5,1.7
			c0.5,1.7,7.7,25.1,13.9,45.8c4.4,14.4,8.8,28.9,11.4,37.7l0.4,1.4l101.1,155.1h55.3V335.6l39.4-34.1v-25.8H916.8V301.4z"/>
                    <path d="M689.1,328.6L639,275.7h-64.5v26l46.3,37.3v221.4c0,36.3-6.6,63.2-19.5,80c-12.5,16.2-31.6,24.1-58.3,24.1
			c-26.4,0-45.3-7.9-57.8-24.1c-12.9-16.8-19.5-43.7-19.5-80v-36.7l-9-0.9c-1.8-0.2-4.1-0.6-5.6-0.9c-0.5-0.5-1-1.1-1.4-1.6
			c0-0.7,0-1.6,0.1-2.6l15.9-19.2V335.6l38.8-34.1v-25.7H324.9v25.7l38.8,34.1v240.8c0,49.7,14.9,88.2,44.3,114.4
			c29.1,25.9,71.2,39.1,125.3,39.1c31.4,0,59.1-6.6,82.3-19.7c23.4-13.1,41.7-31.9,54.5-55.7c12.6-23.6,19-51.8,19-83.9V328.6z"/>
                    <path d="M400.3,704.4c-2.9-3.9-53.9-73-94.3-134.4l-3.7-5.6l-16.2,3.2c-5.3-1.4-14.1-5-15.4-10.3c-2.6-10.1-2-16.9-1.9-18.4
			l5.4-19.8l-2-3.6c-8.1-14.3-12-23-14-28.2c13.7-4.7,25.8-10.8,36.2-18.3c28-20.2,42.2-48.5,42.2-84.1c0-35.3-13.6-62.8-40.4-81.8
			c-25.7-18.2-62.9-27.4-110.6-27.4H0v25.7l38.8,34.1v65.1l3.8,3c12.5,9.8,19.4,15.3,23.1,18.5c-2.5,4.4-5.8,10.2-6.9,11.7
			c-2.5,3.3-3.5,7.8-3.8,10.4c0,0.1-0.1,0.3-0.2,0.5l-16.1,2.4v101.5l3.1,3c3.3,3.2,5.4,5.3,6.6,6.6c-1.1,8.4-2.3,22.8-2.5,26.6
			c-0.3,0.8-1.4,3.8-5.8,11l-1.4,2.4V670L0,704.1v25.7h179.6v-25.7L140.8,670V325.6h18.7c21.3,0,37.6,5.6,49.8,17.2
			c12.1,11.5,17.9,26.8,17.9,46.9c0,20.5-6,36.5-18.4,48.9c-13.2,13.2-25.9,18.4-45.3,18.4h-10l-0.1,41.2L337,782.7h194.2l-53-19.4
			C449.3,752.7,423.8,733.5,400.3,704.4z"/>
                </g>
            </g>
            <g>
                <g>
                    <path className="st0" d="M1872,669.2L1872,669.2c-19.4-18.1-34.4-43.6-44.9-76.6s-15.8-72.1-15.8-117.2l0,0l0,0
			c0-41.8,4.6-77.5,13.9-107.2s22.8-52.5,40.5-68.4c2.1-1.9,4.3-3.7,6.5-5.3c0.8-0.6,1.6-1.1,2.5-1.7c1.4-1,2.8-2,4.2-2.9
			c1.1-0.7,2.3-1.3,3.4-2c1.1-0.6,2.3-1.3,3.4-1.9c1.4-0.7,2.7-1.3,4.1-2c1-0.4,1.9-0.9,2.9-1.3c1.6-0.6,3.2-1.2,4.8-1.8
			c0.8-0.3,1.6-0.5,2.5-0.8c1.8-0.5,3.6-1,5.4-1.5c0.7-0.2,1.3-0.3,2-0.4c2-0.4,4-0.8,6.1-1.1c0.4-0.1,0.9-0.1,1.3-0.2
			c2.3-0.3,4.6-0.6,6.9-0.7c0,0,0,0,0.1,0l0,0c2.3-0.1,4.6-0.3,6.9-0.3c9.9,0,18.8,3.2,27.6,5.7l-0.4-56.3l-14.5-141.2l-14.9,145.1
			c-46.5,1.9-71.7,8.3-109.8,32.1c-34.6,21.6-61.6,52-81,91.2s-29.1,85.1-29.1,137.9c0,50.8,9.4,95.2,28.1,133.2
			s44.9,67.4,78.5,88.3c18.6,11.6,39.1,19.9,61.2,25.1L1872,669.2z"/>
                    <path className="st0" d="M2142.1,344.5c-18.8-38.3-45.3-68.1-79.4-89.3c-14.9-9.3-31-16.5-48.1-21.7l3.3,81.7
			c12.5,16.3,22.6,36.6,30.4,60.9c2,6.4,3.9,13,5.5,19.9l4.8-1c0,0,16.2,7.1,17.7,9.4s1.4,10.4,1.2,10.6s-5.9,9.4-5.9,9.4l-11.1,7.7
			c2.6,19.6,3.9,40.7,3.9,63.4l0,0l0,0c0,42.5-4.7,78.8-14.1,108.9c-9.4,30.2-23,53.3-40.8,69.5c-0.1,0-0.1,0.1-0.2,0.1
			c-1.9,1.7-3.8,3.3-5.8,4.8c-0.7,0.5-1.4,1.1-2.1,1.6c-1.4,1-2.8,1.9-4.2,2.9c-0.8,0.5-1.7,1.1-2.5,1.6c-1.8,1.1-3.7,2.1-5.6,3.1
			c-1.4,0.7-2.8,1.3-4.2,2c-0.6,0.3-1.3,0.6-1.9,0.8c-11.5,4.8-24.1,7.3-37.8,7.3c-4.7,0-8.8-1.2-13.3-1.7l0.5,62l13.8,134.5
			l15.2-148.3c36.7-3.3,69.4-13.8,98-31.7c35.2-21.9,62.5-52.9,81.9-93c19.5-40,29.2-86.6,29.2-139.6
			C2170.4,428,2160.9,382.8,2142.1,344.5z"/>
                    <g>
                        <path className="st1" d="M1883.9,646.4C1883.8,646.4,1883.8,646.4,1883.9,646.4c-16.7-15.6-29.6-37.5-38.6-65.9s-13.6-62-13.6-100.9
				l0,0l0,0c0-36,4-66.7,12-92.3c8-25.5,19.6-45.2,34.8-58.9c1.8-1.6,3.7-3.1,5.6-4.6c0.7-0.5,1.4-1,2.1-1.5
				c1.2-0.8,2.4-1.7,3.6-2.5c1-0.6,2-1.2,3-1.7c1-0.6,2-1.1,3-1.6c1.2-0.6,2.4-1.1,3.6-1.7c0.8-0.4,1.7-0.8,2.5-1.1
				c1.4-0.6,2.7-1,4.1-1.5c0.7-0.2,1.4-0.5,2.1-0.7c1.5-0.5,3.1-0.9,4.7-1.3c0.6-0.1,1.1-0.3,1.7-0.4c1.7-0.4,3.5-0.7,5.2-1
				c0.4-0.1,0.8-0.1,1.2-0.2c2-0.3,3.9-0.5,5.9-0.6l0,0l0,0c2-0.1,3.9-0.2,5.9-0.2c8.5,0,16.6,1.1,24.2,3.3l-0.7-46.9l-12.4-121.5
				L1931,267.6c-40,1.6-61.7,7.2-94.5,27.6c-29.8,18.6-53,44.8-69.7,78.5s-25,73.3-25,118.6c0,43.7,8.1,82,24.2,114.6
				c16.1,32.7,38.7,58,67.6,76c16.1,10,33.6,17.2,52.7,21.6L1883.9,646.4z"/>
                        <path className="st2" d="M1877.8,694.2c-14.3-4.2-27.8-10.2-40-17.9c-27.5-17.1-49.2-41.6-64.6-72.8c-15.5-31.4-23.4-68.8-23.4-111.1
				c0-43.9,8.1-82.7,24.2-115.1c15.9-32.2,38.4-57.5,66.8-75.2c25.9-16.1,56.4-25,90.6-26.4l6.9-0.3l5.5-54.1l4.5,43.5l0.6,36.4
				c-4.6-0.8-9.2-1.2-14-1.3l0,0h-0.5c-0.5,0-1,0-1.6,0c-2.3,0-4.5,0.1-6.2,0.3h-0.4h-0.2c-1.9,0.1-3.9,0.3-6.2,0.7h-0.4
				c-0.3,0-0.6,0.1-0.9,0.1c-1.8,0.3-3.7,0.6-5.7,1.1h-0.2c-0.6,0.1-1.1,0.2-1.7,0.4c-1.7,0.4-3.4,0.9-5.1,1.4
				c-0.8,0.2-1.6,0.5-2.3,0.8c-1.7,0.6-3.2,1.1-4.6,1.7c-0.8,0.3-1.7,0.7-2.5,1.1l-0.2,0.1c-1.4,0.6-2.6,1.2-4,1.9
				c-1,0.5-1.9,1.1-2.9,1.6l-1.1,0.6c-0.8,0.5-1.7,0.9-2.6,1.5c-1.3,0.8-2.6,1.7-3.8,2.6l-0.2,0.1c-0.2,0.2-0.5,0.3-0.7,0.5
				c-0.5,0.4-1.1,0.8-1.6,1.2c-2.2,1.7-4.2,3.3-6.1,5c-16.3,14.6-28.8,35.6-37.1,62.4c-8.2,26.2-12.3,58.1-12.3,94.7
				c0,39.5,4.7,74.2,13.9,103.3c9,28.3,21.9,50.8,38.4,67.1L1877.8,694.2z"/>
                        <path className="st1" d="M2109.9,370.1c-16-32.5-38.4-57.7-67.3-75.7c-12.6-7.9-26.2-14-40.8-18.4l2.8,69.2
				c10.6,13.8,19.1,31,25.7,51.6c1.7,5.4,3.3,11,4.7,16.8l4-0.9c0,0,13.7,6,15,8s1.2,8.9,1,9s-5,8-5,8l-9.4,6.5
				c2.2,16.6,3.3,34.5,3.3,53.7l0,0l0,0c0,36-4,66.7-12,92.3c-8,25.5-19.5,45.2-34.6,58.9l-0.1,0.1c-1.6,1.4-3.2,2.8-4.9,4.1
				c-0.6,0.5-1.2,0.9-1.8,1.4c-1.2,0.8-2.3,1.6-3.5,2.4c-0.7,0.5-1.4,0.9-2.1,1.4c-1.5,0.9-3.1,1.8-4.7,2.6
				c-1.2,0.6-2.4,1.1-3.6,1.7c-0.5,0.2-1.1,0.5-1.6,0.7c-9.8,4.1-20.4,6.2-32.1,6.2c-4,0-7.8-0.3-11.6-0.8l0.8,51.9l11.7,114
				l12.9-125.7c31.1-2.8,58.8-11.7,83.1-26.8c29.8-18.6,52.9-44.9,69.4-78.8c16.5-33.9,24.7-73.4,24.7-118.3
				C2133.9,441,2125.9,402.6,2109.9,370.1z"/>
                        <path className="st2" d="M1940.1,720.3l-0.7-42.7c1.2,0,2.3,0.1,3.5,0.1c12.5,0,24.3-2.3,35.1-6.8c0.6-0.2,1.2-0.5,1.8-0.8
				c1.3-0.6,2.6-1.2,4-1.9c1.8-0.9,3.5-1.9,5.2-2.9c0.6-0.4,1.2-0.7,1.8-1.1l0.6-0.4c1.3-0.9,2.6-1.8,3.9-2.7c0.7-0.5,1.4-1,2-1.5
				c2-1.5,3.7-2.9,5.2-4.3c0.1-0.1,0.2-0.2,0.3-0.3c16.1-14.6,28.5-35.6,36.8-62.4c8.2-26.2,12.3-58,12.3-94.7
				c0-17.6-0.9-34.3-2.7-49.9l6.8-4.7l0.9-1.4c2-3.3,4.3-7,4.7-7.6c-0.1,0.1-0.4,0.5-0.8,1c4.7-4.9,1.6-16.5-0.1-19
				c-1-1.6-2.6-3.9-18.5-10.9l-1.2-0.5c-1-3.6-2-7.1-3.1-10.5c-6.5-20.3-15.1-37.8-25.5-51.9l-2.2-55.1c9.9,3.7,19.3,8.4,28.1,13.9
				c27.5,17.1,49.1,41.5,64.3,72.5c15.3,31.2,23.1,68.7,23.1,111.4c0,43.5-8.1,82.2-23.9,114.8c-15.7,32.4-38.1,57.8-66.5,75.5
				c-22.9,14.3-49.7,22.9-79.6,25.6l-6.6,0.6l-5.6,54.5L1940.1,720.3z"/>
                    </g>
                    <path className="st3" d="M1934.3,299.9h0.5l0,0c4.7,0.1,9.4,0.5,14,1.3l-0.6-36.4l-4.5-43.5l-5.5,54.1l-6.9,0.3
			c-34.2,1.4-64.7,10.2-90.6,26.4c-28.4,17.7-50.8,43-66.8,75.2c-0.6,1.2-1.2,2.5-1.8,3.7l67-5.3c8.2-22.5,19.7-40.3,34-53.2
			c1.9-1.7,3.9-3.4,6.1-5c0.5-0.4,1.1-0.8,1.6-1.2c0.2-0.2,0.5-0.3,0.7-0.5l0.2-0.1c1.2-0.9,2.5-1.7,3.8-2.6c0.9-0.6,1.8-1,2.6-1.5
			l1.1-0.6c0.9-0.5,1.8-1.1,2.9-1.6c1.3-0.7,2.6-1.2,4-1.9l0.2-0.1c0.8-0.4,1.7-0.8,2.5-1.1c1.4-0.6,2.8-1.1,4.6-1.7
			c0.8-0.3,1.6-0.5,2.3-0.8c1.7-0.5,3.4-1,5.1-1.4c0.6-0.1,1.1-0.3,1.7-0.4h0.2c2.1-0.5,4-0.8,5.7-1.1c0.3,0,0.6-0.1,0.9-0.1h0.4
			c2.3-0.3,4.3-0.5,6.2-0.7h0.2h0.4c1.8-0.1,4-0.3,6.2-0.3C1933.3,299.9,1933.8,299.9,1934.3,299.9z"/>
                    <path className="st4" d="M2010.3,287.4l1.8,44l46.7-15.2c-6.4-5.5-13.2-10.5-20.4-15C2029.6,295.7,2020.2,291.1,2010.3,287.4z" />
                    <path className="st4" d="M2101.9,599.9c10.2-21.1,17.2-44.6,20.9-70.3l-32.2-3.4l-39.9,4.4c-1.8,23.2-5.5,44-11.1,62.1
			c-8.4,26.8-20.8,47.8-36.8,62.4c-0.1,0.1-0.2,0.2-0.3,0.3c-1.6,1.4-3.3,2.8-5.2,4.3c-0.6,0.5-1.3,1-2,1.5
			c-1.2,0.9-2.5,1.8-3.9,2.7l-0.6,0.4c-0.6,0.4-1.2,0.8-1.8,1.1c-1.7,1-3.4,2-5.2,2.9c-1.4,0.7-2.7,1.3-4,1.9
			c-0.6,0.3-1.2,0.5-1.8,0.8c-10.8,4.5-22.6,6.8-35.1,6.8c-1.2,0-2.3,0-3.5-0.1l0.7,42.7l3.7,35.9l5.6-54.5l6.6-0.6
			c29.9-2.7,56.6-11.3,79.6-25.6C2063.8,657.7,2086.2,632.3,2101.9,599.9z"/>
                    <path className="st3" d="M1766.6,588.9c2,5,4.1,9.9,6.5,14.7c15.4,31.2,37.1,55.7,64.6,72.8c12.3,7.6,25.7,13.6,40,17.9l-1.8-44.3
			c-16.5-16.3-29.4-38.8-38.4-67.1c-2.8-8.9-5.2-18.3-7.2-28.2l-41.8,4.6L1766.6,588.9z"/>
                    <path className="st5" d="M2101.3,601.3l-70.1,13.4c-7.6,16.5-17.1,30-28.4,40.3c-0.1,0.1-0.2,0.2-0.3,0.3c-1.6,1.4-3.3,2.8-5.2,4.3
			c-0.6,0.5-1.3,1-2,1.5c-1.2,0.9-2.5,1.8-3.9,2.7l-0.6,0.4c-0.6,0.4-1.2,0.8-1.8,1.1c-1.7,1-3.4,2-5.2,2.9c-1.4,0.7-2.7,1.3-4,1.9
			c-0.6,0.3-1.2,0.5-1.8,0.8c-10.8,4.5-22.6,6.8-35.1,6.8c-1.2,0-2.3,0-3.5-0.1l0.7,42.7l3.7,35.9l5.6-54.5l6.6-0.6
			c29.9-2.7,56.6-11.3,79.6-25.6C2063.4,658,2085.5,633,2101.3,601.3z"/>
                    <path className="st6" d="M1837.8,676.3c12.3,7.6,25.7,13.6,40,17.9l-1.8-44.3c-1.5-1.5-2.9-3-4.4-4.6l-59.4,11.4
			C1820.1,664.1,1828.6,670.6,1837.8,676.3z"/>
                </g>
                <g>
                    <g>
                        <g>
                            <path className="st7" d="M2287.4,453l-0.1,28.3l179,224.6h132.3c-33.4-12.2-60-34.5-82.4-62.2c0,0-146-144.3-146-177.2
					c16.2-4.6,30.3-11,42.1-19.6c25.4-18.3,38-43.6,38-76c0-32-12.1-56.5-36.2-73.6c-13.9-9.8-31.3-16.8-52.3-21
					c-6.1,5.3-16.4,13.3-23,13.4c-9.8,0.3-9.7-4.2-11-6c-0.8-1.1-7.3-7.2-12.5-12c-2,0-4-0.1-6-0.1h-175.6v11.2l38.8,34.1v343.5
					l-38.8,34.1v11.2h159.6v-11.2l-38.8-34.1v-359h28.7c23.8,0,42.6,6.7,56.7,20c14,13.3,21,31.4,21,54.1c0,23.1-7.1,41.8-21.3,56
					C2325.6,445.9,2310.8,453,2287.4,453z"/>
                            <path className="st7" d="M2470.2,697.8l-174.9-219.4v-17.7c20.1-1.5,35.4-8.7,50.1-23.4c15.7-15.7,23.6-36.4,23.6-61.6
					c0-24.9-7.9-45.1-23.5-59.9c-15.5-14.7-36.4-22.2-62.2-22.2h-36.7v370.6l38.4,33.7h-142.6l38.4-33.7V313.4l-38.4-33.7h167.1
					c1,0,1.9,0,2.9,0c6.1,5.7,8.3,7.8,9.1,8.6c1.9,4.1,5.7,9.4,16.8,9.4c0.3,0,0.7,0,1.1,0c2.4-0.1,9.5-0.3,24.9-12.8
					c18.2,4,33.5,10.3,45.5,18.9c22.1,15.6,32.8,37.5,32.8,67.1c0,29.9-11.4,52.6-34.7,69.5c-10.8,7.8-24.2,14-39.6,18.3l-5.8,1.6
					v6.1c0,6.5,0,23.7,75.1,106.4c34.4,37.9,69.3,72.6,73,76.2c16.8,20.7,34.5,36.8,53.8,48.8H2470.2z"/>
                            <path className="st7" d="M2710.6,271.7v11.2l38.8,34.1l25.1,215.8l-155.2-197.2c-4.3,2.2-9.6,4.6-12.4,4.2c-5.1-0.7-18-2.8-22-14
					c-2.6-7.4,2.1-19.4,5.7-26.7l-21.5-27.4h-92v11.2l40.4,46.9l-7.6,249.7c2.3,3.2,4.5,6.3,6.8,9.4c9,9.2,17.9,18.3,26.7,27
					l1.5,1.5l1.4,1.7c4,4.9,8,9.4,12.1,13.7l-16.7-264.4l105.5,161.8c7.4-5,18.6-12.1,21.7-12.6c4.2-0.6,12.7,2.8,15,8
					c1.9,4.3-6.2,34.3-10.2,45.1l88,134.9h39.9V317l39.4-34.1v-11.2L2710.6,271.7L2710.6,271.7z"/>
                            <path className="st7" d="M2766.1,697.8l-83.5-128c2-6.1,4.4-14.5,6.3-22.1c4.5-18.3,3.7-22.4,2.4-25.2c-3.5-7.9-13.8-12.8-21.2-12.8
					c-0.8,0-1.5,0.1-2.3,0.2c-1.4,0.2-4.7,0.7-18.2,9.4l-117.7-180.6l17.2,271.6c-8.4-8.3-17.2-17.2-26.2-26.4c0,0-4-5.6-4.9-6.7
					l7.6-250.1l-40.5-47v-0.2h80.2l16.1,20.5c-5.1,11.8-6.4,21.3-3.9,28.3c2.6,7.4,9.7,16.7,28.5,19.2c0.5,0.1,1.1,0.1,1.7,0.1
					c1.8,0,4.5-0.3,9.4-2.3L2785.8,560l-28.7-246.9l-37.9-33.3h113.3l-38.8,33.7V698h-27.6V697.8z"/>
                            <path className="st7" d="M1681.1,516c-14.9-12.9-33.9-21.8-56.9-26.8V487c14.6-4.7,27-11.1,37-19.4c23.2-19.3,34.8-48.3,34.8-86.9
					c0-36.7-12-64.2-35.9-82.3c-23.9-18.1-60.4-27.2-109.3-27.2h-14.3c-3.6,3.7-10.2,10.5-15.5,10.6c-4.7,0.1-15.8-6.1-23.1-10.6
					h-114.7V286l38.8,45.2v27.2l28,25.3l-9,11l-19,3.7v300.2l-38.8,45.2v14.8h160.1c18.7,0,35.9-1.5,51.7-4.4
					c3.2-11.2,9-29.6,9-29.6l37.4,14.9c24.5-11.7,43.2-28.5,56-50.2c13.3-22.6,20-50.2,20-82.7C1717.3,567.2,1705.3,537,1681.1,516z
					 M1504,310.7h37.8c47.5,0,71.3,28.7,71.3,86.2c0,24.5-4.8,43.8-14.4,57.9s-23.1,21.9-40.4,23.3L1504,484V310.7z M1605.8,693
					c-14.7,17.4-36.1,26.1-64.1,26.1H1504V519.9h48.9c23.8,0,42.2,8.4,55.3,25.1c13.1,16.7,19.7,41.1,19.7,73.1
					C1627.9,650.6,1620.6,675.5,1605.8,693z"/>
                            <path className="st7" d="M1391.2,750.7v-3.9l38.8-45.2V405.1l15.3-3L1461,383l-31-28.1v-26.6l-38.8-45.2v-3.9h104.5
					c15.6,9.4,21.7,10.6,25.2,10.6c0.1,0,0.3,0,0.4,0c6.7-0.2,12.8-5,18.6-10.6h11c46.9,0,82.1,8.6,104.5,25.6
					c22,16.7,32.7,41.5,32.7,75.9c0,36.5-10.5,62.9-32,80.8c-8.9,7.4-20.5,13.5-34.3,18l-5.5,1.8v14.5l6.3,1.4
					c21.7,4.7,39.6,13.1,53.4,25c22.5,19.6,33.4,47.3,33.4,84.7c0,30.9-6.3,57.4-18.8,78.6c-11.4,19.3-28,34.7-49.4,45.5l-42.1-16.8
					l-2.6,8.2c-0.2,0.7-4.5,14.1-7.7,25c-14,2.2-29.2,3.4-45.4,3.4L1391.2,750.7L1391.2,750.7z M1496,727.1h45.8
					c30.4,0,54-9.8,70.2-29c15.9-18.8,24-45.8,24-80c0-33.7-7.2-60-21.4-78.1c-14.7-18.7-35.4-28.1-61.6-28.1h-57V727.1z
					 M1496,492.9l63-6.8c19.8-1.6,35.4-10.6,46.3-26.8c10.4-15.4,15.7-36.4,15.7-62.4c0-77.9-43.1-94.2-79.3-94.2H1496V492.9z"/>
                            <path className="st7" d="M1205,679.9V497.6l76.1,4.2l40.4,38.3h11.7V428h-11.7l-40.6,38.4l-75.9,4.4V297.1l151.6,6.3l-6.1-5.3v-27
					l-266.3,0.5v10.7l38.8,34.1v30.4c10.6,11.5,28.9,30.9,28.9,30.9l-4,9L1123,397v263.4l-38.8,34.1v11.2h266.3l25.4-26.9v-5.5
					L1205,679.9z"/>
                            <path className="st7" d="M1092.7,697.8l38.4-33.7V402.4l22.9-9.5l7.5-16.8l-3.7-3.9c-0.2-0.2-16.1-17-26.7-28.5v-30.9l-37.8-33.2
					l249.3-0.5v15.7l-145.6-6.1v190.6l87.3-5.1l40.4-38.2h0.5v96h-0.5l-40.2-38.1l-87.5-4.8v199.1l165.1-6.3l-15,16h-254.4V697.8z"
                            />
                            <path className="st7" d="M926.8,271.7v11.2l38.8,34.1l25.1,215.8L785.2,271.7h-92v11.2l40.4,46.9L724,660.5l-38.8,34.1v11.2H815
					v-11.2l-38.8-34.1l-18.3-292l68.2,104.6c4.6-5,11-10.6,16.9-10.3c10.6,0.5,11,6.9,12,10c0.9,2.6,18.6,61.3,25.4,83.7l97.4,149.4
					h39.9V317l39.4-34.1v-11.2L926.8,271.7L926.8,271.7z"/>
                            <path className="st7" d="M982.2,697.8L887.8,553c-2.7-8.9-6.9-22.7-11.1-36.6c-8.4-27.7-13.5-44.6-14.1-46.2
					c-0.1-0.2-0.1-0.5-0.2-0.8c-0.9-3.5-3.8-14-19-14.7c-0.3,0-0.5,0-0.8,0c-4.8,0-9.8,2-15,6l-79.6-122l20.4,325.7l38.1,33.5H693.7
					l38.3-33.6l9.8-337.3l-40.5-47v-0.2h80.2L1001.9,560l-28.7-246.9l-37.9-33.3h113.3l-38.8,33.7V698h-27.6V697.8z"/>
                            <path className="st7" d="M634.7,271.7h-50.2v11.2l46.3,37.3v226.2c0,38.8-7.2,67.5-21.5,86.1c-14.4,18.7-36.4,28-66.2,28
					c-29.4,0-51.3-9.3-65.7-28c-14.4-18.7-21.5-47.4-21.5-86.1v-27.7c-3.2-0.3-8.7-1.4-9.7-2c-1.3-0.9-5.2-5-6-7s0-10,0-10
					l15.7-18.9V317l38.8-34.1v-11.2H334.9v11.2l38.8,34.1v245.4c0,46.9,13.7,82.6,41,106.9c27.3,24.4,66.9,36.5,118.6,36.5
					c29.8,0,55.6-6.1,77.4-18.4c21.8-12.3,38.7-29.5,50.5-51.7c11.9-22.2,17.8-48.6,17.8-79.2v-238L634.7,271.7z"/>
                            <path className="st7" d="M533.3,697.8c-49.5,0-87.7-11.6-113.3-34.5c-25.4-22.6-38.3-56.6-38.3-100.9v-249l-38.4-33.7H486
					l-38.4,33.7V478l-15.4,18.6L432,499c-1,9.9,0.1,12.6,0.5,13.7c1.6,4.2,7,9.4,9,10.7c1.4,0.9,3.8,1.7,6.1,2.2v20.8
					c0,40.4,7.8,71,23.2,91c15.9,20.6,40.1,31.1,72,31.1c32.3,0,56.7-10.5,72.6-31.1c15.4-20,23.2-50.6,23.2-91v-230l-45.5-36.7h38
					l39.9,42.1v234.8c0,29.1-5.7,54.5-16.9,75.4c-11.1,20.8-27.1,37.1-47.4,48.5C586.3,692,561.6,697.8,533.3,697.8z"/>
                            <path className="st7" d="M163.5,453l-0.1,28.3l179,277.4h132.3c-33.4-12.2-60-34.5-82.4-62.2c0,0-52.7-71.1-94.7-135.1l-11.6,2.3
					c0,0-21.3-3.9-25-18s-2-23-2-23l4.5-16.4c-10.4-18.4-17.1-32.9-17.1-39.9c16.2-4.6,30.3-11,42.1-19.6c25.4-18.3,38-43.6,38-76
					c0-32-12.1-56.5-36.2-73.6s-59.1-25.6-104.8-25.6H10v11.2L48.8,317v64.7c12.3,9.6,29.1,22.9,29.2,24c0.1,1.7-9.9,18.6-11,20
					s-1.9,4.4-2,6s-3,8-3,8l-13.2,2v88.6c4.3,4.1,10.5,10.3,10.2,11.4c-0.6,1.7-2.8,24.7-3,30c-0.1,2.7-3.6,9.3-7.2,15.1v73.6
					L10,694.7v11.2h159.6v-11.2l-38.8-34.1v-359h28.7c23.8,0,42.6,6.7,56.7,20c14,13.3,21,31.4,21,54.1c0,23.1-7.1,41.8-21.3,56
					C201.7,445.9,186.9,453,163.5,453z"/>
                            <path className="st7" d="M346.8,750.7L171.4,478.9v-18.2c20.1-1.5,35.4-8.7,50.1-23.4c15.7-15.7,23.6-36.4,23.6-61.6
					c0-24.9-7.9-45.1-23.5-59.9c-15.5-14.7-36.4-22.2-62.2-22.2h-36.7v370.6l38.4,33.7H18.5l38.4-33.7v-75.1
					C63,578.6,64,574.7,64,572c0.1-4.9,2.1-24.9,2.7-28.1c1.4-4.9-1.5-8.8-9.9-16.9v-78.3l10.6-1.6l1.8-4c3.5-7.8,3.6-9.8,3.7-10.8
					c0-0.4,0.3-1.4,0.5-1.9c1.4-2,5.1-8.4,6.4-10.6c5.8-10.1,6.3-12.1,6.1-14.6c-0.3-4.1-0.3-4.8-29.1-27.4v-64.5l-38.4-33.7h167.1
					c43.8,0,77.6,8.1,100.2,24.1c22.1,15.6,32.8,37.5,32.8,67.1c0,29.9-11.4,52.6-34.7,69.5c-10.9,7.8-24.2,14-39.6,18.3l-5.8,1.6
					v6.1c0,5.3,1.9,14.8,16.5,41l-3.7,13.5l-0.1,0.3c-0.2,1.1-1.9,11.1,2.1,26.5c4.8,18.5,28.6,23.4,31.3,23.9l1.5,0.3l7.9-1.6
					c41.2,62.2,91.5,130.2,92,130.9l0.1,0.1l0.1,0.1c16.9,20.8,34.7,37.1,54.1,49.1h-93.4V750.7z"/>
                        </g>
                        <path className="st8" d="M56.8,331.9l65.9,1.2v-39.6h36.7c25.8,0,46.7,7.5,62.2,22.2c6,5.7,10.9,12.2,14.6,19.5l76.2,1.4
				c-5.3-13.1-14.2-24-26.7-32.8c-22.6-16-56.3-24.1-100.2-24.1h-167l38.4,33.7v18.5H56.8z"/>
                    </g>
                    <path className="st9" d="M440.2,750.7c-19.3-12-37.2-28.3-54.1-49.1l-0.1-0.1l-0.1-0.1c-0.5-0.7-50.8-68.7-92-130.9l-7.9,1.6l-1.5-0.3
			c-2.7-0.5-26.5-5.3-31.3-23.9c-2.7-10.5-2.8-18.4-2.5-22.8l-54.3-7.4l150.4,233.1h93.4V750.7z"/>
                    <path className="st9" d="M122.8,507.6l-65.9-8.9V527c8.4,8.1,11.3,12,9.9,16.9c-0.6,3.2-2.6,23.2-2.7,28.1c-0.1,2.7-1,6.6-7.1,17.1
			v75.1l-38.4,33.7h142.6l-38.4-33.7C122.8,664.2,122.8,507.6,122.8,507.6z"/>
                    <path className="st10" d="M122.8,586.2L61.2,581c-1,2.2-2.4,4.8-4.3,8.1v75.1l-38.4,33.7h142.6l-38.4-33.7L122.8,586.2L122.8,586.2z"
                    />
                    <path className="st10" d="M440.2,750.7c-19.3-12-37.2-28.3-54.1-49.1l-0.1-0.1l-0.1-0.1c-0.4-0.6-35-47.3-70.3-98.7l-68.2-5.8
			l99.3,153.9h93.5V750.7z"/>
                    <polygon className="st9" points="447.7,339.2 447.7,313.4 486,279.7 343.4,279.7 381.7,313.4 381.7,338 		" />
                    <polygon className="st9" points="593.2,279.7 638.7,316.4 638.7,342.7 671.1,343.3 671.1,321.8 631.2,279.7 		" />
                    <path className="st9" d="M654.2,632c7.8-14.6,12.9-31.5,15.3-50.2l-32.6-4.4c-3.1,25.4-10.3,45.5-21.5,60.1
			c-15.9,20.6-40.3,31.1-72.6,31.1c-31.9,0-56.2-10.5-72-31.1c-14.7-19.1-22.5-48-23.2-85.7l-66-9v19.7c0,44.3,12.9,78.3,38.3,100.9
			c25.7,22.9,63.8,34.5,113.3,34.5c28.3,0,53-5.8,73.5-17.4C627.2,669,643.1,652.7,654.2,632z"/>
                    <path className="st10" d="M654.2,632c0.1-0.2,0.3-0.5,0.4-0.7l-33.1-2.8c-1.9,3.2-3.8,6.2-6,9c-15.9,20.6-40.3,31.1-72.6,31.1
			c-31.9,0-56.2-10.5-72-31.1c-5-6.5-9.2-14.1-12.6-22.8l-70.9-6c6.1,22.4,17,40.7,32.7,54.7c25.7,22.9,63.8,34.5,113.3,34.5
			c28.3,0,53-5.8,73.5-17.4C627.2,669,643.1,652.7,654.2,632z"/>
                    <polygon className="st9" points="833.8,346.3 781.3,279.7 701.2,279.7 701.2,279.9 741.7,327 741.2,344.6 748.4,344.8 748,338.7 
			752,344.8 		"/>
                    <polygon className="st9" points="935.2,279.7 973.2,313.1 977.4,349 1009.7,349.6 1009.7,313.4 1048.6,279.7 		" />
                    <polygon className="st9" points="929.5,617 982.2,697.8 1009.7,697.8 1009.7,627.9 		" />
                    <polygon className="st9" points="764,594.6 734.1,590.5 731.9,664.2 693.7,697.8 806.5,697.8 768.4,664.3 		" />
                    <polygon className="st10" points="955.3,656.6 982.2,697.8 1009.7,697.8 1009.7,661.2 		" />
                    <polygon className="st10" points="766.9,640.7 732.7,637.8 731.9,664.2 693.7,697.8 806.5,697.8 768.4,664.3 		" />
                    <path className="st9" d="M1131.1,343.8c2.3,2.6,5,5.4,7.6,8.2l58.3,1.1v-64.3l145.6,6.1v-15.7l-249.3,0.5l37.8,33.2V343.8z" />
                    <polygon className="st9" points="1197,688.2 1197,652.1 1190.1,652.4 1131.1,644.4 1131.1,664.1 1092.7,697.8 1347.1,697.8 
			1362.2,681.9 		"/>
                    <polygon className="st10" points="1287.8,684.7 1197,688.2 1197,677.1 1123.5,670.8 1092.7,697.8 1347.1,697.8 1362.2,681.9 		" />
                    <path className="st9" d="M1430,354.9l2.8,2.5l63.1,1.2v-55.9h45.8c29.7,0,64.1,11,75.4,58.1l29.9,0.6l40,2.7
			c-3-26-13.5-45.5-31.8-59.4c-22.4-17-57.6-25.6-104.5-25.6h-11c-5.7,5.6-11.9,10.4-18.6,10.6c-0.1,0-0.3,0-0.4,0
			c-3.5,0-9.6-1.2-25.2-10.6H1391v3.9l38.8,45.2v26.7H1430z"/>
                    <path className="st9" d="M1690.5,685.4c9.3-15.9,15.2-34.7,17.6-56l-72.7,3.2c-2,27.4-9.9,49.5-23.4,65.5c-16.2,19.2-39.9,29-70.2,29
			H1496v-88.3l-65.9,2.9v59.9l-38.8,45.2v3.9h152.1c16.2,0,31.4-1.1,45.4-3.4c3.2-10.9,7.5-24.3,7.7-25l2.6-8.2l42.1,16.8
			C1662.5,720,1679.1,704.7,1690.5,685.4z"/>
                    <path className="st10" d="M1667.7,712.9l-29.6,1.4l-40-3.4c-14.9,10.7-33.8,16.1-56.4,16.1H1496v-24.8l-65.9-5.6v4.8l-38.8,45.2v3.9
			h152.1c16.2,0,31.4-1.1,45.4-3.4c3.2-10.9,7.5-24.3,7.7-25l2.6-8.2l42.1,16.8C1651,725.9,1659.9,719.8,1667.7,712.9z"/>
                    <path className="st9" d="M2180.7,397.5l65.9,4.5V293.6h36.7c25.8,0,46.7,7.5,62.2,22.2c15.6,14.8,23.5,35,23.5,59.9
			c0,12.5-2,23.9-5.9,34.2l68.5,4.6c7.2-12.5,10.8-26.9,10.8-43.6c0-29.5-10.7-51.4-32.8-67.1c-12.1-8.5-27.4-14.9-45.5-18.9
			c-15.4,12.6-22.5,12.8-24.9,12.8c-0.4,0-0.7,0-1.1,0c-11.1,0-14.9-5.4-16.8-9.4c-0.8-0.8-2.9-3-9.1-8.6c-0.9,0-1.9,0-2.9,0h-167.1
			l38.4,33.7v84.1H2180.7z"/>
                    <path className="st9" d="M2564.1,697.8c-19.2-12-37-28.1-53.8-48.8c-2.9-2.9-25.3-25.1-51.5-53l-67.4,3l78.8,98.8H2564.1z" />
                    <polygon className="st9" points="2246.7,605.5 2180.7,608.4 2180.7,664.1 2142.4,697.8 2285,697.8 2246.7,664.1 		" />
                    <path className="st10" d="M2452,675.1l18.1,22.7h93.9c-11.7-7.3-22.8-16.1-33.5-26.5L2452,675.1z" />
                    <polygon className="st10" points="2151.8,689.6 2142.4,697.8 2285,697.8 2269.2,683.9 		" />
                    <polygon className="st9" points="2719.1,279.7 2757.1,313.1 2771.6,437.5 2793.6,439 2793.6,313.4 2832.5,279.7 		" />
                    <path className="st9" d="M2684.8,431.7l-67.8-86.2c-4.9,2-7.7,2.3-9.4,2.3c-0.6,0-1.1,0-1.7-0.1c-18.8-2.5-25.8-11.8-28.5-19.2
			c-2.5-7-1.2-16.5,3.9-28.3l-16.1-20.5H2485v0.2l40.5,47l-2.8,93.7l14.4,1l-5.2-83l56.4,86.5L2684.8,431.7z"/>
                    <polygon className="st9" points="2692.9,585.6 2766.1,697.8 2793.6,697.8 2793.6,581.2 		" />
                    <path className="st9" d="M2549.1,610.3l-1.2-18.2l-16.3,0.7C2537.6,598.8,2543.4,604.7,2549.1,610.3z" />
                    <polygon className="st10" points="2742.1,661.1 2766.1,697.8 2793.6,697.8 2793.6,658.6 		" />
                </g>
            </g>
        </svg>

    )
}
