import React from "react";

interface Props {
    fill: string;
}

export const SoftwareDevelopmentIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} className="icon">
            <path d="M17.8,8.5l-3.6-3.6l-2.6,2.6L13.1,9l-1.5,1.5l2.6,2.6l2.1-2.1l1-1l0.5-0.5C18.1,9.2,18.1,8.8,17.8,8.5z" />
            <path d="M8.5,0.2L4.9,3.8l2.6,2.6L9,4.9l1.5,1.5l2.6-2.6l-2.1-2.1l-1-1L9.5,0.2C9.2-0.1,8.8-0.1,8.5,0.2z" />
            <path d="M0.2,9.5l3.6,3.6l2.6-2.6L4.9,9l1.5-1.5L3.8,4.9L1.8,6.9l-1,1L0.2,8.5C-0.1,8.8-0.1,9.2,0.2,9.5z" />
            <path d="M9.5,17.8l3.6-3.6l-2.6-2.6L9,13.1l-1.5-1.5l-2.6,2.6l2.1,2.1l1,1l0.5,0.5C8.8,18.1,9.2,18.1,9.5,17.8z" />
            <path d="M9,10.5L7.5,9L9,7.5L10.5,9L9,10.5z" />
        </svg>
    )
}