import React from "react"
import { Container } from "../../layout/Container"
import { Button, ButtonColour, ButtonSize } from "../../layout/Button";
import { BlobThree } from "../../layout/blobs/BlobThree";

interface Props {
}

export const Hiring: React.FC<Props> = () => {
    return (
        <Container className="hiring-container">
            <div className="blob-container">
                <BlobThree fill={"#080a2e08"} />
            </div>
            <h2 className="title">Job Offerings</h2>
            <div id="HumiJobBoard" data-company="idreaminteractive"></div>
            <script
                src="https://idreaminteractive.applytojobs.ca/embed.js"
                async
                defer
            ></script>
            <Button
                buttonConfig={{
                    id: "hiringGradient",
                    colour: ButtonColour.BLUE,
                    ctaLabel: "View Careers",
                    ctaLink: "https://idreaminteractive.humi.ca/job-board",
                    size: ButtonSize.LARGE,
                    externalLink: true,
                    ariaLabel: "View our job offerings"
                }}
            />
        </Container>
    )
}